import PropTypes from 'prop-types';
import { useState } from 'react';

const couponPropTypes = {
  campaign: PropTypes.shape({
    id: PropTypes.string.isRequired,
    advertiser_store_id: PropTypes.string.isRequired,
    advertiser_business_id: PropTypes.string.isRequired,
    validity: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
  coupon: PropTypes.shape({
    code: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
  store: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    logo: PropTypes.string,
    address: PropTypes.shape({
      first_line: PropTypes.string,
      second_line: PropTypes.string.isRequired,
    }).isRequired,
    location: PropTypes.shape({
      type: PropTypes.oneOf(['Point']),
      coordinates: PropTypes.arrayOf(PropTypes.number).isRequired,
    }).isRequired,
  }).isRequired,
};

const Card = ({ coupon, handleFormSubmit }) => {
  const [isFormVisible, setFormVisible] = useState(false);
  const toggleFormVisibility = () => setFormVisible(!isFormVisible);

  return (
    <div className="max-w-sm mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
      <div className="flex items-center p-4">
        <img
          className="w-16 h-16 rounded-full"
          src="https://via.placeholder.com/150" // Replace with actual image URL
          alt="Open House Cafe"
        />
        <div className="ml-4">
          <div className="flex items-center space-x-2">
            <h2 className="text-xl font-bold text-gray-900">
              {coupon.store.name}
            </h2>
            <span className="text-sm text-gray-600">
              {coupon.store.category}
            </span>
          </div>
          <p className="text-sm text-gray-600">
            {coupon.store.address.first_line}
          </p>
          <p className="text-sm text-gray-600">
            {coupon.store.address.second_line}
          </p>
        </div>
      </div>
      <div className="px-4 pb-4">
        <div className="flex items-center mt-2">
          <span style={{ color: '#ef843d' }} className="text-xl font-bold">
            {coupon.campaign.title}
          </span>
        </div>
        <span className="text-sm text-gray-600">
          Expires on: {coupon.campaign.validity}
        </span>
        <div className="flex space-x-4 w-full">
          <button
            style={{ backgroundColor: '#024f8f' }}
            className="mt-4 flex-grow-3 w-5/6 text-white py-2 rounded-lg"
            onClick={toggleFormVisibility}
          >
            {isFormVisible ? coupon.coupon.code : 'GET IT'}
          </button>
          <button
            style={{
              backgroundColor: '#024f8f',
              borderRadius: '50%',
              width: '40px',
              height: '40px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            className="mt-4"
          >
            <i className="fas fa-info text-white"></i>
          </button>
        </div>
        <div className="flex justify-between items-center mt-2 text-sm text-gray-600">
          <span># of coupons shared</span>
        </div>

        {isFormVisible && (
          <div className="px-4 py-4">
            <form onSubmit={(event) => handleFormSubmit(event, coupon)}>
              <div className="mb-4">
                <label
                  htmlFor="mobile"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Email or Mobile
                </label>
                <input
                  type="text"
                  id="contact"
                  name="contact"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="message"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Message
                </label>
                <textarea
                  type="text"
                  id="message"
                  name="message"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  rows="2"
                />
              </div>
              <button
                type="submit"
                style={{ backgroundColor: '#024f8f' }}
                // className="mt-4 flex-grow-3 w-5/6 text-white py-2 rounded-lg"
                className="block mx-auto bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 w-full rounded focus:outline-none focus:shadow-outline"
              >
                Submit
              </button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

Card.propTypes = {
  coupon: PropTypes.shape(couponPropTypes),
  handleFormSubmit: PropTypes.func.isRequired,
};

export default Card;
