import axios from 'axios';
import { useEffect, useState } from 'react';
import Card from './CouponCard';
import CardTab from './tab';

function UserCoupons({ storeId }) {
  const [coupons, setCoupons] = useState([]);

  const getSpecialDeals = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}cbc/store/${storeId}?type=special_deals`
      )
      .then((response) => {
        if (
          response.data &&
          response.data.result &&
          response.data.result.length > 0
        ) {
          setCoupons(response.data.result);
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  };

  const getDealPool = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}cbc/store/${storeId}?type=deal_pool`
      )
      .then((response) => {
        if (
          response.data &&
          response.data.result &&
          response.data.result.length > 0
        ) {
          setCoupons(response.data.result);
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  };

  const selectTab = (tab) => {
    if (tab === 'DEAL POOL') {
      getDealPool();
    } else {
      getSpecialDeals();
    }
  };

  useEffect(() => {
    getSpecialDeals();
  }, [storeId]);

  return (
    <div>
      <CardTab selectTab={selectTab} />
      <div style={{ height: '10px' }}></div>
      <div
        className="flex flex-col gap-2.5 overflow-auto"
        style={{ maxHeight: 'calc(100vh)' }}
      >
        {coupons.map((coupon) => (
          <div key={coupon.coupon.id}>
            <Card coupon={coupon} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default UserCoupons;
