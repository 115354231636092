import moment from 'moment';
import * as yup from 'yup';

export const campaignInitialState = {
  storeId: '',
  title: '',
  campaignType: 'Deal Pool',
  couponGenerateType: true,
  description: '',
  coupons: {
    total: 0,
    imageUrl: '',
    expiryDate: moment().add(14, 'days').toDate(),
  },
  dealPoolCouponCode: '',
  applicationLinks: {
    mobile: '',
    website: '',
  },
  extraText: '',
  startDate: moment().toDate(),
  endDate: moment().add(7, 'days').toDate(),
  mediaPartners: [],
  creditPayment: false,
  cashPayment: true,
  isCouponChange: false,
  mediaPartnersSplitCoupons: [],
  campaignPincodes: [],
};

export const campaignSchema = yup.object().shape({
  storeId: yup.string().required('This field is mandatory'),
  campaignType: yup.string().required('This field is mandatory'),
  title: yup
    .string()
    .max(30, 'This field has to be a maximum of 30 characters!')
    .required('This field is mandatory'),
  description: yup
    .string()
    .max(90, 'This field has to be a maximum of 90 characters!')
    .required('This field is mandatory'),
  coupons: yup.object().shape({
    total: yup
      .number()
      .typeError('Total must be a number')
      .min(100)
      .required('This field is mandatory'),
    imageUrl: yup.string(),
    expiryDate: yup.string().required('This field is mandatory'),
  }),
  dealPoolCouponCode: yup.string().when('couponGenerateType', {
    is: 'Predefined Coupon',
    then: yup.string().required('This field is mandatory'),
  }),
  applicationLinks: yup.object().shape({
    mobile: yup.string(),
    website: yup.string(),
  }),
  extraText: yup.string(),
  startDate: yup.string().required('This field is mandatory'),
  endDate: yup.string().required('This field is mandatory'),
  mediaPartners: yup.array().when('campaignType', {
    is: 'Targeted Campaign',
    then: yup.array().test({
      message: 'Please select minimum three media partners for create campaign',
      test: (arr) => arr.length > 2,
    }),
  }),

  // mediaPartners: yup.array().test({ message: "Please select minimum three media partners for create campaign", test: (arr) => arr.length > 2, }),
  // mediaPartnersSplitCoupons:yup.array().test({ message: "Each media partners should have minimum 10 coupons", test: (arr) => arr.every((item)=>item?.noOfCoupons>10)})
  mediaPartnersSplitCoupons: yup.array().when('isCouponChange', {
    is: true,
    then: yup
      .array()
      .test({ message: '', test: (arr) => arr.length > 2 })
      .test({
        message:
          'Sum of entered coupons value should be equal to Total coupons',
        test: (arr, context) => {
          return (
            arr.reduce(
              (total, num) => Number(total) + Number(num.noOfCoupons),
              0
            ) === context?.parent?.coupons?.total
          );
        },
      })
      .test({
        message: 'Each media partners should have minimum 10 coupons ',
        test: (arr) => arr.every((item) => item?.noOfCoupons > 9),
      }),
  }),
  campaignPincodes: yup.array().when('campaignType', {
    is: 'Deal Pool',
    then: yup.array().test({
      message: 'Please select atleast one pincode',
      test: (arr) => arr?.length,
    }),
  }),
});

export const campaignFormArray = [
  {
    grid: true,
    items: [
      {
        type: 'select',
        disabled: false,
        inputType: '',
        lable: 'SELECT STORE',
        name: 'storeId',
        placeholder: 'Select store ',
        listData: [],
        grid: true,
        xs: 12,
        md: null,
      },
    ],
  },
  {
    grid: true,
    items: [
      {
        type: 'radio',
        name: 'campaignType',
        label: 'Campaign Option',
        options: ['Deal Pool', 'Targeted Campaign'],
        grid: true,
        xs: 12,
        md: 3,
      },
    ],
  },

  {
    grid: true,
    items: [
      {
        type: 'input',
        disabled: false,
        inputType: 'text',
        lable: 'CAMPAIGN TITLE*',
        name: 'title',
        placeholder: 'Enter Campaign Title ',
        grid: true,
        xs: 12,
        md: 6,
      },
      {
        type: 'input',
        disabled: false,
        inputType: 'text',
        lable: 'CAMPAIGN DESCRIPTION*',
        name: 'description',
        placeholder: 'Enter Campaign Description',
        grid: true,
        xs: 12,
        md: 6,
      },
    ],
  },

  {
    grid: true,
    items: [
      {
        type: 'input',
        disabled: false,
        inputType: 'number',
        lable: 'NO. OF COUPONS*',
        name: 'coupons.total',
        nestedObj: true,
        placeholder: 'Enter the number of coupons',
        grid: true,
        xs: 12,
        md: 4,
      },
      {
        type: 'radio',
        name: 'couponGenerateType',
        label: 'COUPON OPTION',
        options: ['Generate Coupon', 'Predefined Coupon'],
        grid: true,
        xs: 12,
        md: 9,
        // style : {marginRight : 10}
      },
      {
        type: 'input',
        disabled: false,
        inputType: 'text',
        lable: 'LINK TO THE APP',
        name: 'applicationLinks.mobile',
        nestedObj: true,
        placeholder: "Enter your business's App's link",
        grid: true,
        xs: 12,
        md: 4,
      },
      {
        type: 'input',
        disabled: false,
        inputType: 'text',
        lable: 'LINK TO THE WEBSITE',
        name: 'applicationLinks.website',
        placeholder: "Enter your business's website's link",
        grid: true,
        nestedObj: true,
        xs: 12,
        md: 4,
      },
    ],
  },
  {
    grid: false,
    divider: true,
  },

  {
    grid: true,
    items: [
      {
        type: 'fileUpload',
        id: 'storeImage',
        name: 'imageUrl',
        label: 'Upload Store Image*',
        xs: 12,
        md: null,
      },
    ],
  },
];
